<script>
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import GroupsSearch from '/~rec/components/group/groups-search.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import GroupTile from '/~rec/components/group/group-tile.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import debounce from 'lodash-es/debounce'
import BaseFab from '/~/components/base/fab/base-fab.vue'
import RecognitionLayout from '/~rec/layouts/view/recognition-layout.vue'
import { useGroups } from '/~rec/composables/groups'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-groups-feed',
  components: {
    BaseButton,
    GroupsSearch,
    EwCatalogProcessor,
    GroupTile,
    BasePlaceholder,
    RecBaseState,
    BaseFab,
    RecognitionLayout,
  },
  setup() {
    const { groups } = useGroups()
    const { myRecId } = useRecProfile()

    return {
      groups,
      myRecId,
      ui,
    }
  },
  data() {
    return {
      selectedFilter: 'recent',
      filterOptions: [
        { text: 'Most recent', value: 'recent' },
        { text: 'Most popular', value: 'popular' },
      ],
      processing: false,
      firstRequest: true,
      searchName: '',
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
    activeTab() {
      const { query } = this.$route

      return query.type || 'my'
    },
    processor() {
      return this.groups[this.activeTab]
    },
    payload() {
      return this.activeTab === 'my'
        ? {
            user: this.myRecId,
            isMember: true,
          }
        : {
            user: this.myRecId,
            isMember: false,
            // isOwner: false,
          }
    },
    loading() {
      return this.processor.processing
    },
  },
  watch: {
    activeTab: {
      handler() {
        this.searchName = ''
        this.selectedFilter = 'recent'
        this.firstRequest = true
        this.getGroups()
      },
      deep: true,
    },
    searchName(value) {
      if (!value) {
        this.firstRequest = true
      }
    },
  },
  async created() {
    this.getGroups()
  },
  methods: {
    searchGroup: debounce(function () {
      this.getGroups()
    }, 1000),
    onSelectFilter(filter) {
      this.selectedFilter = filter
      this.getGroups()
    },
    async getGroups() {
      const filter = this.selectedFilter
      const payload = {
        filter,
        query: this.payload,
        firstRequest: this.firstRequest && this.activeTab === 'my',
      }

      if (this.searchName) {
        payload.query = {
          ...payload.query,
          name: this.searchName,
        }
      }

      await this.processor.getData(payload)
      this.firstRequest = false
    },
    onGroupClick(group) {
      const { id } = group

      this.$router.push({
        name: 'rec-group',
        params: {
          groupId: id,
          initialGroup: group,
        },
      })
    },
    async joinToGroup(group) {
      try {
        this.processing = true
        await group.join()
        this.$notify({
          text: `You have successfully joined the group ${group.name}`,
          type: 'success',
          duration: 2000,
        })
        this.$router.push({
          name: this.$route.name,
          query: {
            type: 'my',
          },
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          text: 'Some thing went wrong, try again later',
          type: 'error',
          duration: 2000,
        })
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <recognition-layout
    title="Groups"
    :filter-options="filterOptions"
    :selected-filter="selectedFilter"
    :active-tab="activeTab"
    :loading="loading"
    @filter-selected="onSelectFilter"
  >
    <template #headerActions>
      <base-button
        v-if="isDesktop"
        class="w-36 max-w-full"
        @click="$router.push({ name: 'rec-group-create' })"
      >
        + Create group
      </base-button>
    </template>
    <template #search>
      <div v-if="loading && !searchName">
        <base-placeholder
          class="mb-[15px] pt-[5px] md:pt-5"
          :height="44"
          rounded-full
        />
      </div>
      <div v-else class="mb-2.5 mt-[5px] md:mb-0 md:mt-5">
        <groups-search v-model="searchName" @input="searchGroup" />
      </div>
    </template>
    <div
      class="flex grow flex-col"
      :class="{
        '-mt-[5px]': loading && searchName,
        '-mt-6': loading && !searchName,
      }"
    >
      <ew-catalog-processor
        :processor="processor"
        :columns="{
          xxs: 1,
          xs: 2,
          lg: 3,
          xl: 4,
        }"
        tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
      >
        <template #empty>
          <rec-base-state
            image="recroom/team-spirit.svg"
            title="Welcome to Groups"
            subtitle="Join or create a group to start comunicating with specific members!"
          />
        </template>

        <template #tile="{ tile: group }">
          <group-tile
            v-if="group"
            :group="group"
            :is-other-group="activeTab === 'other'"
            :processing="processing"
            @click="onGroupClick(group)"
            @join-group="joinToGroup"
          />
        </template>
      </ew-catalog-processor>
    </div>
    <template #fab>
      <base-fab
        class="md:hidden"
        @click="$router.push({ name: 'rec-group-create' })"
      />
    </template>
  </recognition-layout>
</template>
